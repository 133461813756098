import { graphql } from 'gatsby'
import React from 'react'
import BlogPosts from '../components/BlogPosts'
import Fancy from '../components/Fancy'
import Layout from '../components/layouts'

// Query for the Blog Home content in Prismic
export const query = graphql`
  query MyQuery {
    allPrismicPost(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          id
          uid
          type
          tags
          data {
            title {
              raw
            }
            date
            author
            body {
              ... on PrismicPostDataBodyText {
                id
                slice_label
                slice_type
                primary {
                  text {
                    raw
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// Using the queried Blog Home document data, we render the top section
const BlogHomeHead = () => <Fancy />

export const Homepage = ({ data }) => {
  if (!data) return null

  // Define the Blog Home & Blog Post content returned from Prismic
  const posts = data.allPrismicPost.edges

  return (
    <Layout>
      <BlogHomeHead />
      {/* <BlogPosts posts={posts} /> */}
    </Layout>
  )
}

export default Homepage
