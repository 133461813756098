import React from 'react'

export default () => (
  <div className="fancyContainer">
    <div className="crt" />
    <div className="fancy">
      <h1 className="sign">
        <div className="the">= The =</div>
        <div className="happy">Happy</div>
        <div className="lab">= Lab =</div>
      </h1>
    </div>
  </div>
)
